import React from 'react'
import styled from 'styled-components'
import { readableColor } from 'polished'
import { Link } from 'gatsby'

import { Box, Flex } from '../../elements'

const SideBar = ({ navigation, color }) => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    if (isPartiallyCurrent) {
      return { className: 'navlink-active navlink' }
    } else {
      return { className: 'navlink' }
    }
  }

  return (
    <SideBarInner bg={color} as="aside" p={[6, 6, 8]}>
      <Flex
        flexWrap="nowrap"
        flexDirection={['row', 'row', 'row', 'column']}
        alignItems={['center', 'center', 'center', 'flex-start']}
        justifyContent="space-between"
      >
        <Box width={['3rem', '4rem', '5rem', '6rem']}>
          <Link to="/" aria-label="LekoArts, Back to Home">
            <img src='../images/logo.png' draggable={false} />
          </Link>
        </Box>
        <Nav
          color={color}
          mt={[0, 0, 0, 10]}
          as="nav"
          flexWrap="nowrap"
          flexDirection={['row', 'row', 'row', 'column']}
          alignItems="flex-start"
        >
          {navigation.nodes.map(item => (
            <Link getProps={isPartiallyActive} to={item.link} key={item.name}>
              {item.name}
            </Link>
          ))}
        </Nav>
      </Flex>
    </SideBarInner>
  )
}

const SideBarInner = styled(Box)`
  position: fixed;
  height: 100%;
  width: ${props => props.theme.sidebarWidth.big};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  background: ${props => props.bg};

  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    width: ${props => props.theme.sidebarWidth.normal};
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    width: 100%;
  }

  svg {
    fill: ${props => readableColor(`${props.bg}`)};
  }
`

const Nav = styled(Flex)`
  a {
    text-decoration: none;
    color: ${props => readableColor(`${props.color}`)};
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: 1.5;
    &:hover,
    &:focus,
    &.navlink-active {
      color: ${props => props.theme.colors.primary};
    }

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      font-size: ${props => props.theme.fontSizes[2]};
      margin-left: ${props => props.theme.space[4]};
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      margin-left: ${props => props.theme.space[3]};
    }

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: ${props => props.theme.fontSizes[0]};
      margin-left: ${props => props.theme.space[2]};
    }
  }
`


export default SideBar

