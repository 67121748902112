import React from 'react'
import { readableColor } from 'polished'
import styled from 'styled-components'

import { Box } from '../../elements'

const Footer = ({ color }) => {
  return (
    <FooterStyle color={color}>
      <Box p={[6, 6, 8]} fontSize={0}>
        AppShot by AppShot
      </Box>
    </FooterStyle>
  )
}

const FooterStyle = styled.footer`
  position: fixed;
  width: ${props => props.theme.sidebarWidth.big};
  bottom: 0;

  background: ${props => props.color};

  color: ${props => readableColor(`${props.color}`, `${props.theme.colors.grey}`, '#c3c3c3')};

  a {
    color: ${props => readableColor(`${props.color}`)};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    width: ${props => props.theme.sidebarWidth.normal};
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    width: 100%;
  }
`

export default Footer

